import React, { Component } from 'react';
import { Map, InfoWindow, Marker, GoogleApiWrapper } from '@peacechen/google-maps-react';
import '/node_modules/flag-icons/css/flag-icons.min.css';

const BLUE_MARKER = '/images/maps-blue-marker.png';
const PURPLE_MARKER = '/images/maps-purple-marker.png';
const RED_MARKER = '/images/maps-red-marker.png';

// Explicit list of UN ISO-3166 country codes
// prettier-ignore
const COUNTRY_CODE_LIST = ['AD', 'AE', 'AF', 'AG', 'AL', 'AM', 'AO', 'AR', 'AT', 'AU', 'AZ', 'BA', 'BB', 'BD', 'BE', 'BF', 'BG', 'BH', 'BI', 'BJ', 'BN', 'BO', 'BR', 'BS', 'BT', 'BW', 'BY', 'BZ', 'CA', 'CD', 'CF', 'CG', 'CH',
  'CI', 'CL', 'CM', 'CN', 'CO', 'CR', 'CU', 'CV', 'CY', 'CZ', 'DE', 'DJ', 'DK', 'DM', 'DO', 'DZ', 'EC', 'EE', 'EG', 'ER', 'ES', 'ET', 'FI', 'FJ', 'FM', 'FR', 'GA', 'GB', 'GD', 'GE', 'GH', 'GM', 'GN', 'GQ', 'GR', 'GT', 'GW', 'GY',
  'HN', 'HR', 'HT', 'HU', 'ID', 'IE', 'IL', 'IN', 'IQ', 'IR', 'IS', 'IT', 'JM', 'JO', 'JP', 'KE', 'KG', 'KH', 'KI', 'KM', 'KN', 'KP', 'KR', 'KW', 'KZ', 'LA', 'LB', 'LC', 'LI', 'LK', 'LR', 'LS', 'LT', 'LU', 'LV', 'LY', 'MA', 'MC',
  'MD', 'ME', 'MG', 'MH', 'MK', 'ML', 'MM', 'MN', 'MR', 'MT', 'MU', 'MV', 'MW', 'MX', 'MY', 'MZ', 'NA', 'NE', 'NG', 'NI', 'NL', 'NO', 'NP', 'NR', 'NZ', 'OM', 'PA', 'PE', 'PG', 'PH', 'PK', 'PL', 'PS', 'PT', 'PW', 'PY', 'QA', 'RO',
  'RS', 'RU', 'RW', 'SA', 'SB', 'SC', 'SD', 'SE', 'SG', 'SI', 'SK', 'SL', 'SM', 'SN', 'SO', 'SR', 'SS', 'ST', 'SV', 'SY', 'SZ', 'TD', 'TG', 'TH', 'TJ', 'TL', 'TM', 'TN', 'TO', 'TR', 'TT', 'TV', 'TZ', 'UA', 'UG', 'US', 'UY', 'UZ',
  'VA', 'VC', 'VE', 'VN', 'VU', 'WS', 'YE', 'ZA', 'ZM', 'ZW'];

class GoogleMap extends Component {
  constructor() {
    super();
    this.state = {
      activeMarker: {},
      points: [], // Only store generated points in the state
      selectedPlace: {},
      showingInfoWindow: false,
      error: null // Store errors for conditional rendering
    };
  }

  componentDidMount() {
    fetch(process.env.REACT_APP_SERVICES_HOST + '/locations/')
      .then((res) => {
        if (!res.ok) {
          throw new Error('Network response was not ok');
        }
        return res.json();
      })
      .then((json) => {
        // Generate points and stats from fetched data
        const points = generatePoints(json, this.onMarkerClick);
        this.setState({ points });
        generateStats(json); // Use the data directly without storing it in state
        return json;
      })
      .catch((error) => {
        console.error('Error fetching location data:', error);
        this.setState({ error: 'Failed to load map points. Try again later.' });
        removeSpinner();
      });
  }

  onMarkerClick = (props, marker) =>
    this.setState({
      activeMarker: marker,
      selectedPlace: props,
      showingInfoWindow: true
    });

  onInfoWindowClose = () =>
    this.setState({
      activeMarker: null,
      showingInfoWindow: false
    });

  onMapClicked = () => {
    if (this.state.showingInfoWindow) {
      this.setState({
        activeMarker: null,
        showingInfoWindow: false
      });
    }
  };

  render() {
    // Handling errors during data fetch
    if (this.state.error) {
      return <div className="error-message">{this.state.error}</div>;
    }

    return (
      <Map
        className="map"
        google={this.props.google}
        onClick={this.onMapClicked}
        initialCenter={{
          lat: 25,
          lng: 15
        }}
        containerStyle={{ height: '100%', position: 'relative', width: '100%' }}
        style={{ width: '100%', height: '30rem' }}
        zoom={2}
        streetViewControl={false}
      >
        {this.state.points}
        <InfoWindow
          marker={this.state.activeMarker}
          onClose={this.onInfoWindowClose}
          visible={this.state.showingInfoWindow}
        >
          <div>
            <h5>{this.state.selectedPlace?.name || 'No Name'}</h5>
            <span className={`fi fi-${this.state.selectedPlace?.abbr || ''} map_flag flag`}></span>
          </div>
        </InfoWindow>
      </Map>
    );
  }
}

function generatePoints(pointList, onMarkerClick) {
  let records = [];
  const length = pointList.length;
  for (let x = 0; x < length; x++) {
    let point = pointList[x];
    let title = '';
    let pointType;

    // Build title with default fallback
    title += `${point.city || ''}${point.city ? ', ' : ''}`;
    title += `${point.region || ''}${point.region ? ', ' : ''}`;
    title += `${point.country || ''}`;

    // Determine marker type
    if (point.lived) {
      pointType = BLUE_MARKER;
    } else if (point.visited) {
      pointType = RED_MARKER;
    } else {
      pointType = PURPLE_MARKER;
    }

    // Push marker info
    records.push(
      <Marker
        key={title}
        name={title}
        abbr={point.country_abbr?.toLowerCase() || 'unknown'}
        onClick={onMarkerClick}
        position={{ lat: point.lat, lng: point.lng }}
        icon={{ url: pointType }}
      />
    );
  }
  return records;
}

function generateStats(pointList) {
  let countries = [];
  let states = [];
  let territories = [];

  const length = pointList.length;
  for (let x = 0; x < length; x++) {
    let point = pointList[x];

    if (point.country && point.visited) {
      const gridFlagHTML = `<div class="grid_flag_list col-lg-1 col-sm-2 col-3">
        ${point.country}
        <span class="fi fi-${point.country_abbr?.toLowerCase()} grid_flag flag"></span>
      </div>`;

      if (!countries.includes(gridFlagHTML)) {
        if (COUNTRY_CODE_LIST.includes(point.country_abbr)) {
          countries.push(gridFlagHTML);
        } else if (!territories.includes(gridFlagHTML)) {
          territories.push(gridFlagHTML);
        }
      }

      if (
        point.region &&
        point.country === 'United States' &&
        !states.includes(point.region) &&
        point.region !== 'DC'
      ) {
        states.push(point.region);
      }
    }
  }

  // Render stats content
  if (countries.length) {
    removeSpinner();

    document.getElementById('countries').innerHTML =
      `<strong>Countries Visited:</strong> ${countries.length}<div id ="grid_section" class="row align-items-end">${countries.sort().join('')}</div>`;
    document.getElementById('territories').innerHTML =
      `<strong>Territories Visited:</strong> ${territories.length}<div id ="grid_section" class="row align-items-end">${territories.sort().join('')}</div>`;
    document.getElementById('states').innerHTML =
      `<strong>States Visited:</strong> ${states.length}<div id="text_list_section">${states.sort().join(', ')}</div>`;
  }
}

function removeSpinner() {
  const spinner = document.getElementById('spinner');
  if (spinner) {
    spinner.parentNode.removeChild(spinner);
  }
}

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
})(GoogleMap);
